<template>
  <main>
    <ValidationObserver
      slot="body"
      tag="form"
      ref="observer"
      @keydown.enter="validateBeforeSubmit"
      vid="form"
      @submit.prevent
      class="form"
    >
      <div
        class="bg-header"
        :class="{ 'short-bg-header': true, 'top-webview' : checkWebview(), 'padding-bg-regis': isHasCaution}"
      ></div>

      <div
        class="container home-content"
        :class="{'padding-webview' : checkWebview(), 'padding-has-caution': isHasCaution}"
      >
        <h2 class="gift-code-heading">ギフトコード入力</h2>

        <div class="gift-code">
          <div class="gift-code-wrapper">
            <InputField
              v-model="form.giftCode"
              class="gift-code-input"
              :vid="giftCodeFieldName"
              field="ギフトコード"
              :max-length="16"
              type="text"
              :rules="{
                required: true
              }"
              @input="handleInputGiftCode($event)"
            />
          </div>

          <button
            @click.prevent="validateBeforeSubmit"
            type="submit"
            class="btn gift-code-btn"
            :disabled="isRequesting"
          >
            受け取る
            <ChevronRightIcon size="24"/>
          </button>
        </div>
      </div>
    </ValidationObserver>
  </main>
</template>

<script>
import { mapState } from 'vuex'
import { ChevronRightIcon } from 'vue-feather-icons'
import Common from '@/mixins/common.mixin'
import GiftCode from '@/mixins/giftCode.mixin'
import InputField from '@/components/form/InputField'

export default {
  name: 'Index',

  mixins: [Common, GiftCode],

  components: {
    InputField,
    ChevronRightIcon
  },

  computed: {
    ...mapState('auth', ['profile']),

    isHasCaution () {
      return !this.checkWebview() && ((this.profile && this.profile.skip_regis_info) || this.checkMobile())
    }
  },

  data () {
    return {
      form: {
        giftCode: ''
      },
      isRequesting: false
    }
  },

  methods: {
    async validateBeforeSubmit () {
      const isValid = await this.$refs.observer.validate()

      if (isValid) {
        this.onSubmitGiftCode({
          redirectUrl: 'GiftCodeComplete'
        })
      }
    }
  }
}
</script>

<style lang="scss" scoped>
@import 'src/assets/scss/_giftcode.scss';

.gift-code {
  &-btn {
    margin-top: 50px;
  }
}
</style>
